<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <span  class="remarksAlink">食品药品监管总局关于进口医疗器械注册申请人和备案人名称使用中文的公告：<a target="_blank" href="http://www.gov.cn/xinwen/2017-11/02/content_5236633.htm">http://www.gov.cn/xinwen/2017-11/02/content_5236633.htm</a></span>
      <div class="formLine">
        <FromLabel :showIcon="true" label="公司名称(外文)"></FromLabel>
        <div class="allWidth">
          <!-- <i-input maxlength="50" :disabled="isBand" v-model="form.factory_name" placeholder="请输入" class="iviewIptWidth307"></i-input> -->
          <Select :disabled="isBand || Boolean(form.is_approved)" class="iviewIptWidth307" placeholder="请输入/选择公司名称" v-model="form.factory_name" filterable clearable allow-create ref="factory_name" @on-create="handleCreate" @on-change="selectFactory" @on-open-change="openChange">
            <Option v-for="(item, index) in FactoryList" :value="item.name" :key="index">{{ item.name }}</Option>
          </Select>
          <span class="padding5px">(请先检索库内是否有该厂家，如有可直接点击自动同步)</span>
        </div>
      </div>
        <div class="formLine">
        <FromLabel label="公司名称(中文)"></FromLabel>
        <div class="allWidth">
          <!-- <i-input maxlength="50" :disabled="isBand" v-model="form.factory_name" placeholder="请输入" class="iviewIptWidth307"></i-input> -->
          <Input class="iviewIptWidth307" v-model="form.outside_factory_cname" size="large" placeholder="请输入中文名称" />
          <!-- <span class="padding5px">(请先检索库内是否有该厂家，如有可直接点击自动同步)</span> -->
        </div>
      </div>
      <div class="saveDiv clearfix">
        <div v-show="!isBand" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="put()">提交</div>
        <div class="noticeText">(点击提交,将信息提交至管理端等待审核)</div>
        <div v-show="!isBand" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="nextstep()">保存</div>
        <!-- <Button v-show="!isBand" class="button" type="primary" @click="nextstep()"><span class="fontSize14px">保存</span></Button> -->
        <!-- <Button v-show="!isBand" class="button" type="primary" @click="put()"><span class="fontSize14px">提交</span></Button> -->
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import { debounce } from '@/service/utils'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel
  },
  data() {
    return {
      flag: true,
      // 控制图片显示
      loading: false,
      license: [],
      // 厂家列表
      FactoryList: [],
      factName: '',
      // 控制是否是供应商
      issuply: false,
      form: {
        factory_name: '', // 厂家名称
        outside_factory_cname: '',
        factory_id: this.$route.query.id,
        subfactory_id: this.$route.query.subfactory_id
      },
      contract: '',
      LicenseName: '',
      // 工厂id
      // factory_id: this.$route.query.id,
      isBand: false
    }
  },
  //
  created() {},
  mounted() {
    this.getSelectFactoryList()
    this.judeIsband()
  },
  methods: {
    getSelectFactoryList() {
      this.$http
        .get(this.$api.abrodFactoryList, true)
        .then(res => {
          console.log(res)
          this.FactoryList = res.data
        })
        .then(() => {
          this.getOutFactory()
        })
    },
    openChange() {},
    selectFactory(e) {
      for (let i = 0; i < this.FactoryList.length; i++) {
        if (this.FactoryList[i].name === e) {
          this.form.is_approved = 1
          this.form.subfactory_id = this.FactoryList[i].subfactory_id
          this.form.outside_factory_cname = this.FactoryList[i].outside_factory_cname
          console.log(this.FactoryList[i])
          break
        }
      }
    },
    handleCreate(val) {
      this.FactoryList.push({
        name: val
      })
    },
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    getOutFactory() {
      let data = {
        factory_id: this.$route.query.id
      }
      if (data.factory_id != null) {
        this.$http.get(this.$api.abroadFactory, data, true).then(res => {
          console.log(res)
          this.form = res.data
          // var sustain = false
          for (let i = 0; i < this.FactoryList.length; i++) {
            if (this.FactoryList[i].name == this.form.factory_name) {
              return
            }
          }
          this.FactoryList.push({ name: this.form.factory_name, subfactory_id: this.form.subfactory_id,outside_factory_cname: this.form.outside_factory_cname })
        })
      }
    },
    nextstep: debounce(
      function() {
        if (this.form.factory_name == '') {
          this.$Message.warning('请填写公司名称')
          return
        }
        if(!this.flag) return
        this.flag = false
        let data = this.form
        data.factory_id = this.$route.query.id
        console.log(data,this.FactoryList)
        if (this.$route.query.id == null || this.$route.query.id == undefined) {
          delete data.factory_id
        }
        // console.log(data)
        // 如果选择的公司名称没有就给路由带过来的id
        console.log(data,'id')
        if(!data.subfactory_id) {
          data.subfactory_id = this.$route.query.subfactory_id
        }
        this.$http.post(this.$api.factoryManageFactoryInformation, data, false).then(res => {
          console.log(res)
          this.flag = true
          this.$router.push('/factoryManage')
          this.$Message.success('保存成功')
        }).catch(err => {
          this.flag = true
        })
        sessionStorage.setItem('updateList','0')
      },
      1000,
      true
    ),
    put: debounce(
      function() {
        if (this.form.factory_name == '') {
          this.$Message.warning('请填写公司名称')
          return
        }
        if (!this.flag) return
        this.flag = false
        this.form.factory_id = this.$route.query.id
        let data = this.form
        // 先保存 在提交
        this.$http.post(this.$api.factoryManageFactoryInformation, data, true).then(res => {
          // 如果点击传递为空 就为undefined
          this.flag = true
          if (this.$route.query.id == undefined) {
            data.factory_id = res.data.factory_id
          }
        // 如果选择的公司名称没有就给路由带过来的id
        if(!data.subfactory_id) {
          data.subfactory_id = this.$route.query.subfactory_id
        }
          console.log(data)
          this.$http.post(this.$api.abroadFactorySubmit, data, false).then(res => {
            this.$router.push('/factoryManage')
            this.$Message.success('保存成功')
          })
        }).catch(err => {
          this.flag = true
        })
        sessionStorage.setItem('updateList','0')
      },
      1000,
      true
    )
  },
  watch: {}
}
</script>

<style scoped lang="less">
.marginRight18 {
  margin-right: 18px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.imgstyle {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.iviewIptWidth1075px {
  width: 1075px;
}

.content {
  height: 100%;
  .fromDiv {
    position: relative;
    height: 100%;
    .saveDiv {
      width: 400px;
      position: absolute;
      right: 23px;
      bottom: 5px;
    }
  }
}

// .button {
//   width: 50px;
//   height: 50px;
// }
.fontSize14px {
  font-size: 14px;
}
.iviewIptWidth170 {
  width: 170px;
}
.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewidth700px {
  width: 700px;
}

.moreHeight {
  height: 100px;
}
.noticeText{
  position: absolute;
  bottom: -20px;
  color: red;
  right: -20px;
  font-size: 14px;
}
</style>
